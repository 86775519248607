<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>高校</el-breadcrumb-item>
                <el-breadcrumb-item>就业指导</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch  flex align-center justify-between">
                <div class="searchleft">
                    <!-- <el-form :inline="true" :model="searchform" class="demo-form-inline">
                        <el-form-item label="标题">
                            <el-input v-model="searchform.workName"  placeholder="课程标题"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit"  >查询</el-button>
                        </el-form-item>
                    </el-form> -->
                </div>
                <div class="searchright">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAdd">添加课程</el-button>
                </div>
            </div>

            <div class="list">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="title"
                    label="标题">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="300"
                    label="视频">
                        <template slot-scope="scope">
                            <!-- <show-img  :url="scope.row.url" width="300" wid="40px"></show-img> -->
                            <video :src="scope.row.videoUrl" width="160" controls></video>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="createTime"
                    label="发布时间">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="180"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="gotoEdit(scope.row)">编辑</el-button>
                            <el-button type="danger"  @click="delArticle(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <Addcourse :show.sync="show" :selectobj="selectobj" @getList="getList"></Addcourse>
    </div>
</template>
<script>
import apiUrl from '@/api/public'
import _api from '@/api/index'
import Addcourse from './addCourse.vue'
export default{
    components:{
        Addcourse,
    },
    data(){
        return{
            show:false,
            selectobj:null,
            searchform:{},
            list:[],
            total:0,
            pageSize:10,
            pageNo:1,
            loading:false,
        }
    },
    created(){
        this.getList()
    },
    methods:{
        onSubmit(){

        },
        gotoEdit(row){
            this.selectobj=row
            this.show=true
        },
        openAdd(){
            this.selectobj=null
            this.show=true
        },
        getList(){
            this.loading=true
            _api.get(apiUrl.curriculum,{pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                     this.list=res.data.list
                     this.total=res.data.total
                }
            })
        },
        delArticle(row){
            this.$confirm('确认要删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {

                _api.get(apiUrl.curriculumdel,{id:row.id}).then(res=>{
                    if(res.code==200){
                        this.$message.success("删除成功！")
                        this.getList()
                    }
                })
                
            }).catch(() => {
                     
            });
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>


