<template>
    <el-dialog
    :title="status==1?'添加指导':'编辑指导'"
    :visible.sync="show"
    width="60%"
    @open="open"
    :before-close="handleClose">
    <div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="标题">
                <el-input v-model="form.title" style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item label="视频">
                <upload-video :videoUrl="form.videoUrl" @videoSuccess="videoSuccess" @changVideoStatus="changVideoStatus" :videoStatus="videoStatus"></upload-video>
            </el-form-item>
            <el-form-item label="内容">
                <div class="editor-container">
                    <Uediter style="overflow:auto;height:auto;" :defaultMsg="defaultMsg" :config="config" ref="ue"></Uediter>
                </div>
            </el-form-item>
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import Uediter from '@/components/ue.vue';
import UploadVideo from '@/components/uploadVideo/Index'
import apiUrl from '@/api/public'
import _api from '@/api/index'
export default{
    components: {Uediter,UploadVideo},
    props:{
        show:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            defaultMsg: '',
            config: {
                initialFrameWidth: 995,
                initialFrameHeight: 400
            },
            status:1,
            form:{
                id:null,
                title:null,
                coverPic:null,
                videoUrl:null,
                content:null
            },
            classlist:[],
            videoStatus: false,
            statusFlag: 1, //1视频上传中、2视频上传成功、3视频上传失败
        }
    },
    methods:{
        open(){
            if(this.selectobj){
                this.status=2
                for(let k in this.form){
                    this.form[k]=this.selectobj[k]
                }
                setTimeout(()=>{
                    this.$refs.ue.setUEContent(this.selectobj.content)
                },300)
            }else{
                this.status=1
                setTimeout(()=>{
                    this.$refs.ue.setUEContent('')
                },300)
            }
        },
        changVideoStatus(states) {
            if (states === 'upload') {
                this.videoStatus = true
                this.statusFlag = 1
            } else if (states === 'success') {
                this.statusFlag = 2
                setTimeout(() => {
                    this.videoStatus = false
                }, 1500)
            } else if (states === 'error') {
                this.statusFlag = 3
                setTimeout(() => {
                    this.videoStatus = false
                }, 1500)
            }
        },
        videoSuccess(val) {
            this.form.videoUrl = val[0]
            this.form.coverPic = val[1]
            // this.formAddAdmin1.coverPicture = val[1]
            // console.log('companyPropaganda', this.companyForm.companyPropaganda)
        },
        confirm(){
            this.form.content=this.$refs.ue.getUEContent()
            if(!this.form.title){
                this.$message.warning("请输入标题！")
                return
            }
            let apitype=this.status==1?'post':'put'
            _api[apitype](apiUrl.curriculum,this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.form={
                        id:null,
                        title:null,
                        coverPic:null,
                        videoUrl:null,
                        content:null
                    }
                    this.$emit("getList",null)
                    this.handleClose()
                }
            })
        },
        handleClose(){
            this.$emit("update:show",false)
        }
    }
}
</script>


